import ajax from "./ajax";

export const getPaymetMethods = () => {
  return ajax("/invoices/payment_methods", { server: "python" });
};

export const setDefaultPaymentMethod = (data) => {
  return ajax("/invoices/payment_methods", {
    server: "python",
    method: "PUT",
    data,
  });
};
